exports.components = {
  "component---src-pages-1-password-index-js": () => import("./../../../src/pages/1password/index.js" /* webpackChunkName: "component---src-pages-1-password-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-coaching-js": () => import("./../../../src/pages/coaching.js" /* webpackChunkName: "component---src-pages-coaching-js" */),
  "component---src-pages-customerio-index-js": () => import("./../../../src/pages/customerio/index.js" /* webpackChunkName: "component---src-pages-customerio-index-js" */),
  "component---src-pages-help-scout-index-js": () => import("./../../../src/pages/help-scout/index.js" /* webpackChunkName: "component---src-pages-help-scout-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knime-index-js": () => import("./../../../src/pages/KNIME/index.js" /* webpackChunkName: "component---src-pages-knime-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-practice-better-index-js": () => import("./../../../src/pages/practice-better/index.js" /* webpackChunkName: "component---src-pages-practice-better-index-js" */),
  "component---src-pages-thumbtack-index-js": () => import("./../../../src/pages/thumbtack/index.js" /* webpackChunkName: "component---src-pages-thumbtack-index-js" */),
  "component---src-pages-vendr-index-js": () => import("./../../../src/pages/vendr/index.js" /* webpackChunkName: "component---src-pages-vendr-index-js" */),
  "component---src-pages-wealthsimple-index-js": () => import("./../../../src/pages/wealthsimple/index.js" /* webpackChunkName: "component---src-pages-wealthsimple-index-js" */),
  "component---src-pages-zapier-index-js": () => import("./../../../src/pages/zapier/index.js" /* webpackChunkName: "component---src-pages-zapier-index-js" */)
}

